<template>
  <div>
    <perseu-card title="Benefícios do plano">
      <template #title-right>
        <v-btn color="secondary" @click="openForm">Novo benefício</v-btn>
      </template>
      <template #content>
        <v-data-table :headers="headers" :items="benefits">
          <template #item.actions="{item}">
            <table-action-button
              text="Editar"
              icon="edit"
              small
              @click="openForm(item)"
            />
            <table-action-button
              text="Remover"
              icon="delete"
              small
              color="red"
              @click="deleteBenefit(item.id)"
            />
          </template>
        </v-data-table>
      </template>
    </perseu-card>
    <v-dialog v-model="dialogForm" max-width="800">
      <perseu-card title="Formulário de benefício">
        <template #title-left>
          <back-button @click="dialogForm = false" />
        </template>
        <template #content>
          <v-form ref="form" lazy-validation @keyup.native.enter="save">
            <v-text-field
              :rules="[$rules.required]"
              v-model="benefit.name"
              label="Nome"
            />
            <v-text-field
              v-model="benefit.description"
              label="Descrição"
            />
          </v-form>
        </template>
        <template #actions>
          <v-btn large color="secondary" @click="save">
            Salvar
          </v-btn>
        </template>
      </perseu-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  createBenefit,
  deleteBenefit,
  updateBenefit,
} from "@/services/paypal-service";

export default {
  name: "BenefitsSection",
  props: {
    benefits: {
      type: Array,
      default: () => [],
    },
    idPlan: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Nome",
        value: "name",
        width: "40%",
      },
      {
        text: "Descrição",
        value: "description",
      },
      {
        text: "Ações",
        value: "actions",
        width: "10%",
      },
    ],
    benefit: {},
    dialogForm: false,
  }),
  methods: {
    openForm(benefit) {
      this.dialogForm = true;
      if (benefit.id) {
        this.benefit = benefit;
      } else {
        this.benefit = {};
        this.$refs.form.resetValidation();
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        if (this.benefit.id) {
          await this.update();
        } else {
          await this.create();
        }
        this.$toasted.global.defaultSuccess();
        this.dialogForm = false;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      const { data } = await createBenefit(this.idPlan, this.benefit);
      this.$emit("add-benefit", { benefit: data });
    },
    async update() {
      await updateBenefit(this.idPlan, this.benefit);
      this.$emit("update-benefit", { benefit: this.benefit });
    },
    async deleteBenefit(idBenefit) {
      try {
        this.$store.dispatch("loading/openDialog");
        await deleteBenefit(idBenefit);
        this.$emit("remove-benefit", { idBenefit });
        this.$toasted.global.defaultSuccess();
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
